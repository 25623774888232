import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "styled-components"

import { getFormattedCallAssistCost } from "src/components/CallAssistActivate/getFormattedCallAssistCost"
import {
  HREF_MINUT_MARKETING_CALL_ASSIST_INFO,
  HREF_MINUT_MARKETING_GUARD_ASSIST_INFO,
} from "src/constants/hrefs"
import { useAppData } from "src/context/useAppData"
import { useFetchCallAssistActivationEstimate } from "src/data/callAssist/queries/callAssistQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { IMButtonLegacyProps } from "src/ui/Button/MButtonLegacy"
import PhotoCallAssist from "src/ui/images/photo-call-assist-1.jpg"
import PhotoGuardAssist from "src/ui/images/photo-guard-assist-1.jpg"
import { LearnMore } from "src/ui/Link/LearnMore"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import "react-lazy-load-image-component/src/effects/opacity.css"

type ServicesItemProps = {
  name: React.ReactNode
  title: React.ReactNode
  children: React.ReactNode
  activateHref: IMButtonLegacyProps["appHref"]
  learnMoreHref: string
  imgSrc: string
}
function ServicesItem({
  name,
  title,
  children,
  activateHref,
  learnMoreHref,
  imgSrc,
}: ServicesItemProps) {
  const { t, langKeys } = useTranslate()

  return (
    <Container>
      <ServicesItemBox>
        <ImageBox src={imgSrc} alt="" effect="opacity"></ImageBox>

        <div>
          <MText variant="subtitle">{name}</MText>
          <MText variant="heading2">{title}</MText>

          <MText variant="body" color="secondary">
            {children}
          </MText>

          <ServicesItemActions>
            <MButton appHref={activateHref} variant="secondary">
              {t(langKeys.get_started)}
            </MButton>

            <LearnMore href={learnMoreHref} />
          </ServicesItemActions>
        </div>
      </ServicesItemBox>
    </Container>
  )
}

const Container = styled.div`
  container-type: inline-size;
`

const ImageBox = styled(LazyLoadImage)`
  border-radius: 8px;
  object-fit: cover;

  /* Necessary since LazyLoadImage wraps this component in a span: */
  height: 100%;
  width: 100%;
`

const ServicesItemActions = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
`

const ServicesItemBox = styled.div`
  display: grid;
  gap: ${spacing.L} ${spacing.XL2};

  @container (min-width: 600px) {
    grid-template-columns: 280px 1fr;
    /* Col1 customizations */
    & > :first-child {
      max-height: 280px;
      aspect-ratio: 1;
    }
    /* Col2 customizations */
    & > :last-child {
      max-width: 65ch;
    }
  }
`

export function CallAssistGridItem() {
  const { t, langKeys } = useTranslate()

  const { currentSubscription } = useAppData()
  const fetchCallAssistActivationEstimate =
    useFetchCallAssistActivationEstimate({
      options: { enabled: !!currentSubscription },
    })
  const callAssistEstimate = fetchCallAssistActivationEstimate.data
  const { unitPriceMonthly } = callAssistEstimate
    ? getFormattedCallAssistCost(callAssistEstimate)
    : { unitPriceMonthly: null }

  function getPrice() {
    if (fetchCallAssistActivationEstimate.isError) {
      return "$10"
    }
    if (unitPriceMonthly == null) {
      return <MSkeleton width="5ch" inline />
    }
    return unitPriceMonthly
  }
  const price = getPrice()
  const month = t(langKeys.month).toLocaleLowerCase()
  const home = t(langKeys.home).toLocaleLowerCase()

  return (
    <ServicesItem
      name={t(langKeys.call_assist)}
      title={t(langKeys.call_assist_title_description)}
      activateHref={Routes.CallAssistActivate.location()}
      learnMoreHref={HREF_MINUT_MARKETING_CALL_ASSIST_INFO}
      imgSrc={PhotoCallAssist}
    >
      <p>{t(langKeys.call_assist_description)}</p>
      <p>
        {price}/{month}/{home}
      </p>
    </ServicesItem>
  )
}

export function GuardAssistGridItem() {
  const { t, langKeys } = useTranslate()

  const price = `${t(langKeys.from_price, { price: "$10" })}/${t(
    langKeys.month
  )}/${t(langKeys.home)}`.toLocaleLowerCase()

  return (
    <ServicesItem
      name={t(langKeys.response_service)}
      title={t(langKeys.guard_assist_title_description)}
      activateHref={Routes.ResponseService.location()}
      learnMoreHref={HREF_MINUT_MARKETING_GUARD_ASSIST_INFO}
      imgSrc={PhotoGuardAssist}
    >
      <p>{t(langKeys.guard_assist_description)}</p>
      <p>{price}</p>
    </ServicesItem>
  )
}
